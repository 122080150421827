import { Box, Typography } from "@mui/material";

import HomepageCTA from "./HomepageCTA";

export default function Homepage() {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 800,
            letterSpacing: "-3.2px",
            textDecoration: "none",
            textAlign: "center",
            maxWidth: "726px",
          }}
        >
          Move faster with the power of <span className="highlight">Data</span>
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "0 15rem",
          }}
        >
          <HomepageCTA />
        </div>
      </div>
    </Box>
  );
}
