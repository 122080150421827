import { Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "@remix-run/react";

export default function HomepageCTA() {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate("/truetracts");
      }}
      variant="contained"
      color="primary"
      endIcon={<ArrowForward />}
      size="large"
      sx={{
        fontSize: "19.2px",
        fontWeight: 600,
        lineHeight: "23.04px",
        padding: "15px 20px",
        marginTop: "40px",
      }}
    >
      Get Started
    </Button>
  );
}
