import Homepage from "src/modules/homepage/components/Homepage";

import type { MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => {
  return [
    { title: "TrueTracts" },
    { name: "description", content: "TrueTracts interface" },
  ];
};

export default function Index() {
  return <Homepage />;
}
